<template>
    <component
        :is="component"
        :is-menu-expanded="isMenuExpanded"
        :tabs="tabNavigation"
        :survey-table-data="currentTableData"
        :action-menu="[activeSurveyActionMenu, pendingSurveyActionMenu, draftSurveyActionMenu, finishedSurveyActionMenu][tabNavigation.currentIndex.value]"
        :alert-modal="tabNavigation.currentIndex.value === 1 ? withdrawSurveyAlertModal : deleteSurveyAlertModal"
        :confirm-modal="requestSurveyConfirmModal"
        @open-modal="(state, id) => (state.isExpanded.value = true) & (state.currentId.value = id)"
        @close-modal="(state, element) => (state.isExpanded.value = false) & element.focus()"
        @open-menu="(state, id) => (state.isExpanded.value = true) & (state.currentId.value = id)"
        @close-menu="$event.isExpanded.value = false"
        @editSurveyQuestion="(_, surveyId) => $router.push({name: 'ClientSurveysQuestion', params: {surveyId}})"
        @duplicateSurvey="(_, surveyId) => $router.push({name: 'ClientSurveysDuplicate', params: {surveyId}})"
        @updateServeyStatus="methods.updateServeyStatus"
        @deleteSurvey="methods.deleteSurvey"
        @withdrawRequest="methods.withdrawRequest"
        @openBlank="methods.openBlank"
        @tableauDataDownload="(_, surveyId) => methods.tableauDataDownload(surveyId)"
    />
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, reactive, computed, watch
} from 'vue';
import axios from 'axios';
import store from '@/store';
import {
    SurveyIndexActive as Active,
    SurveyIndexPending as Pending,
    SurveyIndexDraft as Draft,
    SurveyIndexFinished as Finished
} from '@/components/04_Templates/Client';
import {useRoute, useRouter} from 'vue-router';

export default defineComponent({
    inheritAttrs: false,
    components: {
        Active, Pending, Draft, Finished
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup(_, $) {
        const $route = useRoute();
        const $router = useRouter();

        /**
         * alias - テンプレートコンポーネントのエイリアス名
         * @type {Array<String>}
         */
        const alias = ['Active', 'Pending', 'Draft', 'Finished'];

        /**
         * tabNavigation - タブ表示のデータ
         * @type {Object}
         * @property {red<Number>}   currentIndex - 現在のインデックス
         * @property {Boolean}       isNavigation - ナビゲーションモードを有効にする
         * @property {Array<Object>} tabs         - タブのデータ
         */
        const tabNavigation = {
            currentIndex: ref(0),
            isNavigation: true,
            tabs: [
                reactive({
                    id: 'active',
                    label: '実施中',
                    to: {name: 'ClientSurveysActive'}
                }),
                reactive({
                    id: 'pending',
                    label: '申請中',
                    to: {name: 'ClientSurveysPending'}
                }),
                reactive({
                    id: 'draft',
                    label: '下書き',
                    to: {name: 'ClientSurveysDraft'}
                }),
                reactive({
                    id: 'finished',
                    label: '終了',
                    to: {name: 'ClientSurveysFinished'}
                })
            ]
        };

        /**
         * activeSurveyTableData - 実施中アンケートテーブルデータ
         * @type {Object}
         * @property {ref<Boolean>}                                   isLoading              - データ読み込み中ステータス
         * @property {Array<Array>}                                   headRows               - テーブルヘッダー情報
         * @property {Array<Array>}                                   bodyRows               - テーブルボディ情報
         * @property {Object}                                         pagination             - ページネーション情報
         * @property {ref<Number>}                                    pagination.currentPage - 現在のページ数
         * @property {Array<Nubmer>}                                  pagination.pages       - ページ数の一覧
         * @property {Object<{ message: String, repletion: String }>} emptyMessage           - データが空だった場合の表示メッセージ
         */
        const activeSurveyTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'id',
                        content: 'アンケートID',
                        resizing: 'hug',
                        isHead: true
                    },
                    {
                        key: 'bonus_name',
                        content: 'タイトル',
                        isHead: true
                    }, {
                        key: 'status',
                        content: 'ステータス',
                        isHead: true
                    }, {
                        key: 'cap',
                        content: '回答数',
                        isHead: true
                    }, {
                        key: 'start_date',
                        content: 'アンケート開始日',
                        isHead: true
                    }, {
                        key: 'end_date',
                        content: 'アンケート終了日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: '実施中のアンケートが存在しません',
                repletion: '現在実施しているアンケートがここに表示されます。'
            }
        };

        /**
         * pendingSurveyTableData - 申請中アンケートテーブルデータ
         * @type {Object}
         * @property {ref<Boolean>}                                   isLoading              - データ読み込み中ステータス
         * @property {Array<Array>}                                   headRows               - テーブルヘッダー情報
         * @property {Array<Array>}                                   bodyRows               - テーブルボディ情報
         * @property {Object}                                         pagination             - ページネーション情報
         * @property {ref<Number>}                                    pagination.currentPage - 現在のページ数
         * @property {Array<Nubmer>}                                  pagination.pages       - ページ数の一覧
         * @property {Object<{ message: String, repletion: String }>} emptyMessage           - データが空だった場合の表示メッセージ
         */
        const pendingSurveyTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'id',
                        content: 'アンケートID',
                        resizing: 'hug',
                        isHead: true
                    },
                    {
                        key: 'bonus_name',
                        content: 'タイトル',
                        isHead: true
                    }, {
                        key: 'status',
                        content: 'ステータス',
                        isHead: true
                    }, {
                        key: 'cap',
                        content: '回答数',
                        isHead: true
                    }, {
                        key: 'start_date',
                        content: 'アンケート開始日',
                        isHead: true
                    }, {
                        key: 'end_date',
                        content: 'アンケート終了日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: '申請中のアンケートが存在しません',
                repletion: '配信申請中のアンケートがここに表示されます。'
            }
        };

        /**
         * draftSurveyTableData - 下書きアンケートテーブルデータ
         * @type {Object}
         * @property {ref<Boolean>}                                   isLoading              - データ読み込み中ステータス
         * @property {Array<Array>}                                   headRows               - テーブルヘッダー情報
         * @property {Array<Array>}                                   bodyRows               - テーブルボディ情報
         * @property {Object}                                         pagination             - ページネーション情報
         * @property {ref<Number>}                                    pagination.currentPage - 現在のページ数
         * @property {Array<Nubmer>}                                  pagination.pages       - ページ数の一覧
         * @property {Object<{ message: String, repletion: String }>} emptyMessage           - データが空だった場合の表示メッセージ
         */
        const draftSurveyTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'id',
                        content: 'アンケートID',
                        resizing: 'hug',
                        isHead: true
                    },
                    {
                        key: 'bonus_name',
                        content: 'タイトル',
                        isHead: true
                    }, {
                        key: 'status',
                        content: 'ステータス',
                        isHead: true
                    }, {
                        key: 'cap',
                        content: '回答数',
                        isHead: true
                    }, {
                        key: 'start_date',
                        content: 'アンケート開始日',
                        isHead: true
                    }, {
                        key: 'end_date',
                        content: 'アンケート終了日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: '下書きのアンケートが存在しません',
                repletion: '下書きとして保存したアンケートがここに表示されます。'
            }
        };

        /**
         * finishedSurveyTableData - 終了アンケートテーブルデータ
         * @type {Object}
         * @property {ref<Boolean>}                                   isLoading              - データ読み込み中ステータス
         * @property {Array<Array>}                                   headRows               - テーブルヘッダー情報
         * @property {Array<Array>}                                   bodyRows               - テーブルボディ情報
         * @property {Object}                                         pagination             - ページネーション情報
         * @property {ref<Number>}                                    pagination.currentPage - 現在のページ数
         * @property {Array<Nubmer>}                                  pagination.pages       - ページ数の一覧
         * @property {Object<{ message: String, repletion: String }>} emptyMessage           - データが空だった場合の表示メッセージ
         */
        const finishedSurveyTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'id',
                        content: 'アンケートID',
                        resizing: 'hug',
                        isHead: true
                    },
                    {
                        key: 'bonus_name',
                        content: 'タイトル',
                        isHead: true
                    }, {
                        key: 'status',
                        content: 'ステータス',
                        isHead: true
                    }, {
                        key: 'cap',
                        content: '回答数',
                        isHead: true
                    }, {
                        key: 'start_date',
                        content: 'アンケート開始日',
                        isHead: true
                    }, {
                        key: 'end_date',
                        content: 'アンケート終了日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: '終了したアンケートが存在しません',
                repletion: '終了したアンケートがここに表示されます。'
            }
        };

        /**
         * component - コンポーネント名算出処理
         * @return {String} - ステップに応じたコンポーネント名
         */
        const component = computed(() => alias[tabNavigation.currentIndex.value]);

        /**
         * activeSurveyActionMenu - 実施中アンケート操作メニュー
         * @type {Object}
         * @property {ref<Boolean>}  isExpanded - メニュー開閉フラグ
         * @property {ref<String>}   currentId  - 現在開いているメニューのID
         * @property {String}        placement  - メニュー表示位置
         * @property {Array<Object>} menus      - メニューの内容
         */
        const activeSurveyActionMenu = {
            isExpanded: ref(false),
            currentId: ref(''),
            placement: 'bottom-right',
            menus: [
                {label: '複製して新規作成', emitEvent: 'duplicateSurvey'}
            ]
        };

        /**
         * pendingSurveyActionMenu - 申請中アンケート操作メニュー
         * @type {Object}
         * @property {ref<Boolean>}  isExpanded - メニュー開閉フラグ
         * @property {ref<String>}   currentId  - 現在開いているメニューのID
         * @property {String}        placement  - メニュー表示位置
         * @property {Array<Object>} menus      - メニューの内容
         */
        const pendingSurveyActionMenu = {
            isExpanded: ref(false),
            currentId: ref(''),
            placement: 'bottom-right',
            menus: [
                {label: '複製して新規作成', emitEvent: 'duplicateSurvey'},
                {label: '申請を取り下げる', appearance: 'danger', emitEvent: 'openModal'}
            ]
        };

        /**
         * draftSurveyActionMenu - 下書きアンケート操作メニュー
         * @type {Object}
         * @property {ref<Boolean>}  isExpanded - メニュー開閉フラグ
         * @property {ref<String>}   currentId  - 現在開いているメニューのID
         * @property {String}        placement  - メニュー表示位置
         * @property {Array<Object>} menus      - メニューの内容
         */
        const draftSurveyActionMenu = {
            isExpanded: ref(false),
            currentId: ref(''),
            placement: 'bottom-right',
            menus: [
                {label: '配信申請', emitEvent: 'updateServeyStatus'},
                {label: '複製して新規作成', emitEvent: 'duplicateSurvey'},
                {label: '設問の編集', emitEvent: 'editSurveyQuestion'},
                {label: '削除', appearance: 'danger', emitEvent: 'openModal'}
            ]
        };

        /**
         * finishedSurveyActionMenu - 終了アンケート操作メニュー
         * @type {Object}
         * @property {ref<Boolean>}  isExpanded - メニュー開閉フラグ
         * @property {ref<String>}   currentId  - 現在開いているメニューのID
         * @property {String}        placement  - メニュー表示位置
         * @property {Array<Object>} menus      - メニューの内容
         */
        const finishedSurveyActionMenu = {
            isExpanded: ref(false),
            currentId: ref(''),
            placement: 'bottom-right',
            menus: [
                {label: '複製して新規作成', emitEvent: 'duplicateSurvey'},
                {label: '集計データダウンロード', emitEvent: 'tableauDataDownload', key: 'download'}
            ]
        };

        /**
         * deleteSurveyAlertModal - 削除アラートモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - 読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const deleteSurveyAlertModal = {
            id: 'delete-alert-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'alert',
            closable: false,
            title: 'アンケートを削除しますか？',
            description: '削除されたアンケートは、元に戻すことができません。',
            ariaDescribedby: 'delete-alert-modal-description'
        };

        /**
         * withdrawSurveyAlertModal - 申請取り下げアラートモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - 読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const withdrawSurveyAlertModal = {
            id: 'withdraw-alert-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'alert',
            closable: false,
            title: '申請を取り下げますか？',
            description: '取り下げた場合、アンケートは下書きとして保存されます。',
            ariaDescribedby: 'withdraw-alert-modal-description'
        };

        /**
         * requestSurveyConfirmModal - 申請取り下げアラートモーダル情報
         * @type {Object}
         * @property {String}        id              - モーダルのID名
         * @property {ref<String>}   currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>}  isExpanded      - モーダル起動フラグ
         * @property {String}        appearance      - モーダルの表示形式
         * @property {Boolean}       closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}        title           - 表示するタイトルの文字
         * @property {Array<String>} description     - 表示する説明文
         * @property {String}        ariaDescribedby - aria-describedby属性値
         */
        const requestSurveyConfirmModal = {
            id: 'request-confirm-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            appearance: 'confirm',
            closable: false,
            title: '申請いただけないアンケートです',
            description: [
                '設問が登録されていないか、開始希望日が昨日以前に設定されているアンケートは配信することができません。',
                '「設問の編集」より設問の設定を行なってください。'
            ],
            ariaDescribedby: 'request-confirm-modal-description'
        };

        /**
         * currentTableData - ページに応じたテーブルデータの算出
         */
        const currentTableData = computed(() => [activeSurveyTableData, pendingSurveyTableData, draftSurveyTableData, finishedSurveyTableData][tabNavigation.currentIndex.value]);


        /**
         * isDownloading - tableauの集計ファイルダウンロード制御state
         * @type {ref<Boolean>}
         */
        const isDownloading = ref(false);

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * openBlank - 外部リンク別窓開き
             * @param {String} url - 開くURL
             */
            openBlank(url) {
                window.open(url);
            },
            /**
             * updateServeyStatus - 配信申請処理
             * @param {Number} surveyId - アンケートのID
             * @returns {Void}
             */
            updateServeyStatus(modal, surveyId) {
                axios.patch(
                    `/api/v2/client_account/bonuses/${surveyId}/set_status`,
                    {status: 'is_pending'},
                    {headers: store.state.auth}
                ).then(() => {
                    // トーストを表示
                    $.emit('displayToast', {
                        message: '配信申請を行いました',
                        isError: false,
                        icon: 'Success'
                    });

                    // 再読み込みを実施
                    methods.getSurveyTableData(currentTableData.value, {status: `is_${alias[tabNavigation.currentIndex.value].toLowerCase()}`});
                }).catch(() => {
                    // アラートモーダルを表示
                    modal.isExpanded.value = true;
                    modal.currentId.value = surveyId;
                });
            },
            /**
             * deleteSurvey - アンケート削除処理
             * @returns {Void}
             */
            deleteSurvey() {
                const modal = deleteSurveyAlertModal;
                const currentId = modal.currentId.value;

                // すでに処理中の場合は何もしない
                if (modal.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                modal.isLoading.value = true;

                axios.delete(
                    `/api/v2/client_account/bonuses/${currentId}`,
                    {headers: store.state.auth}
                ).then(() => {
                    // トーストを表示
                    $.emit('displayToast', {
                        message: 'アンケートを削除しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // モーダルを閉じる
                    modal.isExpanded.value = false;

                    // 再読み込みを実施
                    methods.getSurveyTableData(currentTableData.value, {status: `is_${alias[tabNavigation.currentIndex.value].toLowerCase()}`});
                }).catch(() => {
                    // エラートーストを表示
                    $.emit('displayToast', {
                        message: 'アンケートを削除に失敗しました',
                        isError: true,
                        icon: 'Failed'
                    });
                }).finally(() => {
                    // 読み込みフラグを更新（完了）
                    modal.isLoading.value = false;
                });
            },
            /**
             * withdrawRequest - 申請取り下げ処理
             * @returns {Void}
             */
            withdrawRequest() {
                const modal = withdrawSurveyAlertModal;
                const currentId = modal.currentId.value;

                // すでに処理中の場合は何もしない
                if (modal.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                modal.isLoading.value = true;

                axios.patch(
                    `/api/v2/client_account/bonuses/${currentId}/set_status`,
                    {status: 'is_draft'},
                    {headers: store.state.auth}
                ).then(() => {
                    // トーストを表示
                    $.emit('displayToast', {
                        message: '配信申請を取り下げました',
                        isError: false,
                        icon: 'Success'
                    });

                    // モーダルを閉じる
                    modal.isExpanded.value = false;

                    // 再読み込みを実施
                    methods.getSurveyTableData(currentTableData.value, {status: `is_${alias[tabNavigation.currentIndex.value].toLowerCase()}`});
                }).catch(() => {
                    // エラートーストを表示
                    $.emit('displayToast', {
                        message: '配信申請の取り下げに失敗しました',
                        isError: true,
                        icon: 'Failed'
                    });
                }).finally(() => {
                    // 読み込みフラグを更新（完了）
                    modal.isLoading.value = false;
                });
            },
            /**
             * getSurveys - APIからアンケート一覧を取得
             * @param {Object} params - 送信するパラメータ
             * @returns {Object<{ bonuses: Array, meta: Object}>}
             */
            async getSurveys(params) {
                const result = {};

                await axios.get('/api/v2/client_account/bonuses', {
                    headers: store.state.auth,
                    params: Object.assign(params, $route.query)
                }).then((response) => {
                    const {bonuses, meta} = response.data;

                    result.bonuses = bonuses.map((bonus) => ([
                        {key: 'survey_id', content: bonus.id, resizing: 'hug'},
                        {key: 'bonus_name', content: bonus.bonus_name},
                        {key: 'status', content: bonus.status},
                        {key: 'cap', content: `${bonus.validate_count}/${bonus.cap}`},
                        {key: 'start_date', content: new Date(bonus.start_date).toLocaleDateString() || ''},
                        {key: 'end_date', content: new Date(bonus.end_date).toLocaleDateString() || ''},
                        {
                            key: 'action',
                            content: bonus.id,
                            resizing: 'hug',
                            downloadEnabled: bonus.can_tableau_data
                        }
                    ]));

                    result.meta = meta;
                });

                return result;
            },
            /**
             * getSurveyTableData - アンケート一覧を取得・データ更新
             * @param {Object} targetTable - 更新するテーブルデータ
             * @param {Object} params      - 送信するパラメータ
             * @returns {Void}
             */
            getSurveyTableData(targetTable, params) {
                // すでに読み込み中の場合は取得しない
                if (targetTable.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                targetTable.isLoading.value = true;

                // データ取得
                methods.getSurveys(params).then((response) => {
                    const {bonuses, meta} = response;

                    // テーブルデータを更新
                    targetTable.bodyRows.value = bonuses;

                    // ページネーションを更新
                    targetTable.pagination.currentPage.value = meta.current_page;
                    targetTable.pagination.pages.value = Array.from(Array(meta.total_pages).keys(), (idx) => idx + 1);

                    // 現在のページが合計ページ数を逸脱している場合
                    if (meta.current_page > meta.total_pages) {
                        $router.replace({query: {...$route.query, ...{page: meta.total_pages}}});
                    }
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    targetTable.isLoading.value = false;
                });
            },

            tableauDataDownload(surveyId) {
                // すでに読み込み中の場合は取得しない
                if (isDownloading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                isDownloading.value = true;

                axios.get(`/api/v2/client_account/bonuses/${surveyId}/tableau_download`, {
                    headers: store.state.auth,
                    responseType: 'blob'
                }).then((response) => {
                    // 取得したファイルをダウンロードできるようにする
                    const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));

                    const link = document.createElement('a');
                    link.href = fileURL;

                    link.setAttribute('download', 'data.zip');
                    link.click();

                    // トーストを表示
                    $.emit('displayToast', {
                        message: 'ダウンロードを開始しました',
                        isError: false,
                        icon: 'Success'
                    });
                }).catch((error) => {
                    // トーストを表示
                    $.emit('displayToast', {
                        message: error[0],
                        isError: true,
                        icon: 'Failed'
                    });
                }).finally(() => {
                    isDownloading.value = false;
                });
            }
        };

        /**
         * URLパス変更監視処理
         * @type {Function}
         */
        watch(() => $route.path, (path) => {
            const lastSegment = path.substring(path.lastIndexOf('/') + 1);
            const index = alias.map((alia) => alia.toLowerCase()).indexOf(lastSegment);

            // /surveys 以外のページヘ遷移する場合は何もしない
            if (index === -1) {
                return;
            }

            tabNavigation.currentIndex.value = index;
            methods.getSurveyTableData(currentTableData.value, {status: `is_${alias[tabNavigation.currentIndex.value].toLowerCase()}`});
        }, {immediate: true});

        /**
         * URLクエリ変更監視処理
         * @type {Function}
         */
        watch(() => $route.query, (query) => {
            const currentQuery = tabNavigation.tabs[tabNavigation.currentIndex.value].to.query || {};

            // クエリリセット（別ページへの遷移）の場合は処理しない
            if (!Object.values(query).length) {
                return;
            }

            tabNavigation.tabs[tabNavigation.currentIndex.value].to.query = query;

            // クエリに変更がない場合は読み込み処理を行わない
            if (JSON.stringify(Object.entries(currentQuery).sort()) === JSON.stringify(Object.entries(query).sort())) {
                return;
            }

            const currentTable = currentTableData.value;

            currentTable.pagination.currentPage.value = parseInt(query.page, 10);
            methods.getSurveyTableData(currentTable, {status: `is_${alias[tabNavigation.currentIndex.value].toLowerCase()}`});
        }, {immediate: true});

        return {
            tabNavigation,
            activeSurveyTableData,
            pendingSurveyTableData,
            draftSurveyTableData,
            finishedSurveyTableData,
            activeSurveyActionMenu,
            pendingSurveyActionMenu,
            draftSurveyActionMenu,
            finishedSurveyActionMenu,
            currentTableData,
            component,
            deleteSurveyAlertModal,
            withdrawSurveyAlertModal,
            requestSurveyConfirmModal,
            methods
        };
    }
});
</script>

<style lang="scss" scoped></style>
