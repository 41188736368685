<template>
    <header id="header" class="t-header">
        <div class="t-header__inner">
            <div class="t-header__logo">
                <button type="button" class="t-header__menu" :aria-pressed="isExpanded" @click="$emit('toggleMenu', !isExpanded)"><icon name="Menu" /><span class="u-altText">サイドメニューを展開</span></button>
                <router-link class="t-header__top" :to="{name: 'AdminClientAccount'}"><h1>CASHb DATA管理画面</h1></router-link>
            </div>
            <div class="t-header__utils">
                <menus v-bind="userMenu" :is-expanded="userMenu.isExpanded.value">
                    <template v-slot:menu-trigger="menuTriggerProps">
                        <button ref="trigger" type="button" class="t-header__user" v-bind="menuTriggerProps.trigger" @click="$emit('openUserMenu', $event)"><span class="t-header__username">{{userMenu.userName.value || '管理者'}}</span></button>
                    </template>

                    <template v-slot:menu-list="menuListProps">
                        <menu-list v-bind="menuListProps.menus" @close="$emit('closeUserMenu', $event)">
                            <template v-slot:menu-item="menuItemProps">
                                <menu-item v-bind="menuItemProps.item" @click="$emit(menuItemProps.item.emitEvent, $event)">
                                    <template v-if="menuItemProps.item.beforeIcon" v-slot:before><icon :name="menuItemProps.item.beforeIcon" /></template>
                                    {{menuItemProps.item.label}}
                                    <template v-if="menuItemProps.item.afterIcon" v-slot:after><icon :name="menuItemProps.item.afterIcon" /></template>
                                </menu-item>
                            </template>
                        </menu-list>
                    </template>
                </menus>
            </div>
        </div>
    </header>

    <modal
        v-bind="serverErrorModal"
        :id="serverErrorModal.id"
        :is-expanded="serverErrorModal.isExpanded.value"
        @close="$emit('closeModal', $event)"
    >
        <template v-slot:header="modalHeaderProps">
            <modal-header :title="serverErrorModal.title" v-bind="modalHeaderProps.header" />
        </template>

        <div :id="serverErrorModal.ariaDescribedby">
            <p v-for="desc in serverErrorModal.description" :key="desc">{{desc}}</p>
        </div>

        <template v-slot:footer>
            <modal-footer>
                <button-group layout="end">
                    <standard-button appearance="primary" @click="$emit('closeModal', $event)">OK</standard-button>
                </button-group>
            </modal-footer>
        </template>
    </modal>

    <toasts :toasts="toasts">
        <template v-slot:toast="toastProps">
            <toast-item v-bind="toastProps.toast">
                <template v-if="toastProps.toast.icon" v-slot:icon><icon :name="toastProps.toast.icon" /></template>
            </toast-item>
        </template>
    </toasts>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Icon from '@/components/01_Atoms/Icons';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';
import Menus, {MenuItem, MenuList} from '@/components/01_Atoms/Menus';
import Modal, {ModalHeader, ModalFooter} from '@/components/01_Atoms/ModalDialogs';
import Toasts, {ToastItem} from '@/components/01_Atoms/Toasts';

export default defineComponent({
    inheritAttrs: false,
    components: {
        Icon, ButtonGroup, StandardButton, Modal, ModalHeader, ModalFooter, Menus, MenuItem, MenuList, Toasts, ToastItem
    },
    props: {
        isExpanded: {
            type: Boolean,
            default: false
        },
        userMenu: {
            type: Object,
            default: () => ({})
        },
        serverErrorModal: {
            type: Object,
            default: () => ({})
        },
        toasts: {
            type: Array,
            default: () => []
        }
    }
});
</script>

<style lang="scss" scoped>
.t-header {
    padding-top: 72px;
    min-width: 1280px;
    max-width: 1920px;

     @at-root {
        .t-header__inner {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            display: flex;
            align-items: center;
            width: 100%;
            height: 72px;
            padding: 0 24px 0 16px;
            background: var.$color-primary-40;
            z-index: 99;

            a {
                text-decoration: none;

                h1 {
                    color: #000;
                    min-width: 350px;
                }
            }
        }

        .t-header__logo,
        .t-header__utils {
            display: flex;
            align-items: center;
            flex-basis: 100%;
        }

        .t-header__utils {
            justify-content: flex-end;
        }

        .t-header__menu {
            display: inline-flex;
            align-items: center;
            padding: 4px 10px;
            min-height: 40px;
            color: var.$color-text-white;
            background: rgba(#F2F6F9, .2);
            border-radius: 2px;
            transition: background-color .3s ease 0s;

            @include mixin.hover {
                background: rgba(#F2F6F9, .6);
            }
        }

        .t-header__top {
            &:not(:first-child) {
                margin-left: 40px;
            }
        }

        .t-header__user {
            padding: 4px 16px;
            min-height: 40px;
            color: var.$color-text-medium;
            font-size: 1.4rem;
            background: rgba(#F2F6F9, .2);
            border-radius: 2px;
            transition: background-color .3s ease 0s;

            // メニュー展開時はイベントを受け付けない（focusend(メニュー閉じる) → click(ボタン押下)の順でエベントが発生し、ボタン押下でメニューが閉じないため）
            &[aria-expanded="true"] {
                pointer-events: none;
            }

            @include mixin.hover {
                background: rgba(#F2F6F9, .6);
            }
        }

        .t-header__username {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            white-space: normal;
            overflow: hidden;
            max-width: 200px;
        }
     }
}
</style>
