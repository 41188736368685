<template>
    <div class="a-avator">
        <div v-if="$slots.image" class="a-avator__image"><slot name="image" /></div>
        <div v-if="primary || secondary" class="a-avator__body">
            <span v-if="primary" class="a-avator__primary">{{primary}}</span>
            <span v-if="secondary" class="a-avator__secondary">{{secondary}}</span>
            <span v-if="tertiary" class="a-avator__tertiary">{{tertiary}}</span>
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        primary: {
            type: String,
            default: ''
        },
        secondary: {
            type: String,
            default: ''
        },
        tertiary: {
            type: String
        }
    }
});
</script>

<style lang="scss">
.a-avator {
    display: flex;
    align-items: center;

    @at-root {
        .a-avator__image {
            display: flex;
        }

        .a-avator__body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            max-width: 254px;
        }

        .a-avator__primary,
        .a-avator__secondary,
        .a-avator__tertiary {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .a-avator__primary {
            font-size: 1.4rem;
            line-height: 1;
            color: var.$color-text-high;
        }

        .a-avator__secondary {
            font-size: 1rem;
            line-height: 1.3;
            color: var.$color-text-disabled;

            &:not(:first-child) {
                margin-top: 6px;
            }
        }

        .a-avator__tertiary {
            font-size: 1rem;
            line-height: 1.3;
            color: var.$color-text-disabled;
        }
    }
}
</style>
