<template>
    <component
        :is="component"
        :is-menu-expanded="isMenuExpanded"
        :tabs="tabNavigation"
        :survey-table-data="currentTableData"
        :action-menu="[activeSurveyActionMenu, pendingSurveyActionMenu, draftSurveyActionMenu, waitingSurveyActionMenu, finishedSurveyActionMenu][tabNavigation.currentIndex.value]"
        :alert-modal="tabNavigation.currentIndex.value === 1 ? withdrawSurveyAlertModal : deleteSurveyAlertModal"
        :confirm-modal="tabNavigation.currentIndex.value === 3 ? isRequestedToTrueConfirmModal : isRequestedToFalseConfirmModal"
        :models="models"
        :forms="forms"
        v-model:activeClientId="models.activeSearch.clientId"
        v-model:activeClientName="models.activeSearch.clientName"
        v-model:activeSurveyId="models.activeSearch.surveyId"
        v-model:pendingClientId="models.pendingSearch.clientId"
        v-model:pendingClientName="models.pendingSearch.clientName"
        v-model:pendingSurveyId="models.pendingSearch.surveyId"
        v-model:draftClientId="models.draftSearch.clientId"
        v-model:draftClientName="models.draftSearch.clientName"
        v-model:draftSurveyId="models.draftSearch.surveyId"
        v-model:waitingClientId="models.waitingSearch.clientId"
        v-model:waitingClientName="models.waitingSearch.clientName"
        v-model:waitingSurveyId="models.waitingSearch.surveyId"
        v-model:finishedClientId="models.finishedSearch.clientId"
        v-model:finishedClientName="models.finishedSearch.clientName"
        v-model:finishedSurveyId="models.finishedSearch.surveyId"
        @set-active-model-value="(name, value) => models.activeSearch[name] = value"
        @set-pending-model-value="(name, value) => models.pendingSearch[name] = value"
        @set-draft-model-value="(name, value) => models.draftSearch[name] = value"
        @set-waiting-model-value="(name, value) => models.waitingSearch[name] = value"
        @set-finished-model-value="(name, value) => models.finishedSearch[name] = value"
        @open-modal="(state, id) => (state.isExpanded.value = true) & (state.currentId.value = id)"
        @close-modal="(state, element) => (state.isExpanded.value = false) & element.focus()"
        @open-menu="(state, id) => (state.isExpanded.value = true) & (state.currentId.value = id)"
        @close-menu="$event.isExpanded.value = false"
        @updateSurveyStatus="methods.updateSurveyStatus"
        @deleteSurvey="methods.deleteSurvey"
        @withdrawRequest="methods.withdrawRequest"
        @openBlank="methods.openBlank"
        @tableauDataDownload="(_, surveyId) => methods.tableauDataDownload(surveyId)"
        @searchTableData="methods.handleSearchTableData"
        @updateRequestStatus="methods.updateRequestStatus"
        @get-survey-name="methods.getSurveyName"
        @exportExcelFile="(_, surveyId) => methods.exportExcelFile(surveyId)"
    />
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, reactive, computed, watch
} from 'vue';
import axios from 'axios';
import store from '@/store';
import {
    AdminSurveyIndexActive as Active,
    AdminSurveyIndexPending as Pending,
    AdminSurveyIndexDraft as Draft,
    AdminSurveyIndexWaiting as Waiting,
    AdminSurveyIndexFinished as Finished
} from '@/components/04_Templates/Admin';
import {useRoute, useRouter} from 'vue-router';

export default defineComponent({
    inheritAttrs: false,
    components: {
        Active, Pending, Draft, Waiting, Finished
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup(_, $) {
        const $route = useRoute();
        const $router = useRouter();

        /**
         * alias - テンプレートコンポーネントのエイリアス名
         * @type {Array<String>}
         */
        const alias = ['Active', 'Pending', 'Draft', 'Waiting', 'Finished'];

        /**
         * tabNavigation - タブ表示のデータ
         * @type {Object}
         * @property {red<Number>}   currentIndex - 現在のインデックス
         * @property {Boolean}       isNavigation - ナビゲーションモードを有効にする
         * @property {Array<Object>} tabs         - タブのデータ
         */
        const tabNavigation = {
            currentIndex: ref(0),
            isNavigation: true,
            tabs: [
                reactive({
                    id: 'active',
                    label: '実施中',
                    to: {name: 'AdminClientSurveysActive'}
                }),
                reactive({
                    id: 'pending',
                    label: '申請中',
                    to: {name: 'AdminClientSurveysPending'}
                }),
                reactive({
                    id: 'draft',
                    label: '下書き',
                    to: {name: 'AdminClientSurveysDraft'}
                }),
                reactive({
                    id: 'waiting',
                    label: '請求待ち',
                    to: {name: 'AdminClientSurveysWaiting'}
                }),
                reactive({
                    id: 'finished',
                    label: '終了（請求済み）',
                    to: {name: 'AdminClientSurveysFinished'}
                })
            ]
        };

        /**
         * activeSurveyTableData - 実施中アンケートテーブルデータ
         * @type {Object}
         * @property {ref<Boolean>}                                   isLoading              - データ読み込み中ステータス
         * @property {Array<Array>}                                   headRows               - テーブルヘッダー情報
         * @property {Array<Array>}                                   bodyRows               - テーブルボディ情報
         * @property {Object}                                         pagination             - ページネーション情報
         * @property {ref<Number>}                                    pagination.currentPage - 現在のページ数
         * @property {Array<Nubmer>}                                  pagination.pages       - ページ数の一覧
         * @property {Object<{ message: String, repletion: String }>} emptyMessage           - データが空だった場合の表示メッセージ
         */
        const activeSurveyTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'id',
                        content: 'アンケートID',
                        resizing: 'hug',
                        isHead: true
                    },
                    {
                        key: 'bonus_name',
                        content: 'アンケート名',
                        isHead: true
                    }, {
                        key: 'client_id',
                        content: 'クライアントID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'client_name',
                        content: 'クライアント名',
                        isHead: true
                    }, {
                        key: 'cap',
                        content: '回答数',
                        isHead: true
                    }, {
                        key: 'start_date',
                        content: 'アンケート開始日',
                        isHead: true
                    }, {
                        key: 'end_date',
                        content: 'アンケート終了日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: '実施中のアンケートが存在しません',
                repletion: '現在実施しているアンケートがここに表示されます。'
            }
        };

        /**
         * pendingSurveyTableData - 申請中アンケートテーブルデータ
         * @type {Object}
         * @property {ref<Boolean>}                                   isLoading              - データ読み込み中ステータス
         * @property {Array<Array>}                                   headRows               - テーブルヘッダー情報
         * @property {Array<Array>}                                   bodyRows               - テーブルボディ情報
         * @property {Object}                                         pagination             - ページネーション情報
         * @property {ref<Number>}                                    pagination.currentPage - 現在のページ数
         * @property {Array<Nubmer>}                                  pagination.pages       - ページ数の一覧
         * @property {Object<{ message: String, repletion: String }>} emptyMessage           - データが空だった場合の表示メッセージ
         */
        const pendingSurveyTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'bonus_id',
                        content: 'アンケートID',
                        resizing: 'hug',
                        isHead: true
                    },
                    {
                        key: 'bonus_name',
                        content: 'アンケート名',
                        isHead: true
                    }, {
                        key: 'client_id',
                        content: 'クライアントID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'client_name',
                        content: 'クライアント名',
                        isHead: true
                    }, {
                        key: 'cap',
                        content: '回答数',
                        isHead: true
                    }, {
                        key: 'start_date',
                        content: 'アンケート開始日',
                        isHead: true
                    }, {
                        key: 'end_date',
                        content: 'アンケート終了日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: '申請中のアンケートが存在しません',
                repletion: '配信申請中のアンケートがここに表示されます。'
            }
        };

        /**
         * draftSurveyTableData - 下書きアンケートテーブルデータ
         * @type {Object}
         * @property {ref<Boolean>}                                   isLoading              - データ読み込み中ステータス
         * @property {Array<Array>}                                   headRows               - テーブルヘッダー情報
         * @property {Array<Array>}                                   bodyRows               - テーブルボディ情報
         * @property {Object}                                         pagination             - ページネーション情報
         * @property {ref<Number>}                                    pagination.currentPage - 現在のページ数
         * @property {Array<Nubmer>}                                  pagination.pages       - ページ数の一覧
         * @property {Object<{ message: String, repletion: String }>} emptyMessage           - データが空だった場合の表示メッセージ
         */
        const draftSurveyTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'client_id',
                        content: 'アンケートID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'bonus_name',
                        content: 'アンケート名',
                        isHead: true
                    }, {
                        key: 'client_id',
                        content: 'クライアントID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'client_name',
                        content: 'クライアント名',
                        isHead: true
                    }, {
                        key: 'cap',
                        content: '回答数',
                        isHead: true
                    }, {
                        key: 'start_date',
                        content: 'アンケート開始日',
                        isHead: true
                    }, {
                        key: 'end_date',
                        content: 'アンケート終了日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: '下書きのアンケートが存在しません',
                repletion: '下書きとして保存したアンケートがここに表示されます。'
            }
        };

        /**
         * waitingSurveyTableData -請求待ちアンケートテーブルデータ
         * @type {Object}
         * @property {ref<Boolean>}                                   isLoading              - データ読み込み中ステータス
         * @property {Array<Array>}                                   headRows               - テーブルヘッダー情報
         * @property {Array<Array>}                                   bodyRows               - テーブルボディ情報
         * @property {Object}                                         pagination             - ページネーション情報
         * @property {ref<Number>}                                    pagination.currentPage - 現在のページ数
         * @property {Array<Nubmer>}                                  pagination.pages       - ページ数の一覧
         * @property {Object<{ message: String, repletion: String }>} emptyMessage           - データが空だった場合の表示メッセージ
         */
        const waitingSurveyTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'id',
                        content: 'アンケートID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'bonus_name',
                        content: 'アンケート名',
                        isHead: true
                    }, {
                        key: 'client_id',
                        content: 'クライアントID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'client_name',
                        content: 'クライアント名',
                        isHead: true
                    }, {
                        key: 'cap',
                        content: '回答数',
                        isHead: true
                    }, {
                        key: 'start_date',
                        content: 'アンケート開始日',
                        isHead: true
                    }, {
                        key: 'end_date',
                        content: 'アンケート終了日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: '請求待ちのアンケートが存在しません',
                repletion: '請求待ちのアンケートがここに表示されます。'
            }
        };

        /**
         * finishedSurveyTableData - 終了アンケートテーブルデータ
         * @type {Object}
         * @property {ref<Boolean>}                                   isLoading              - データ読み込み中ステータス
         * @property {Array<Array>}                                   headRows               - テーブルヘッダー情報
         * @property {Array<Array>}                                   bodyRows               - テーブルボディ情報
         * @property {Object}                                         pagination             - ページネーション情報
         * @property {ref<Number>}                                    pagination.currentPage - 現在のページ数
         * @property {Array<Nubmer>}                                  pagination.pages       - ページ数の一覧
         * @property {Object<{ message: String, repletion: String }>} emptyMessage           - データが空だった場合の表示メッセージ
         */
        const finishedSurveyTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'bonus_id',
                        content: 'アンケートID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'bonus_name',
                        content: 'アンケート名',
                        isHead: true
                    }, {
                        key: 'client_id',
                        content: 'クライアントID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'client_name',
                        content: 'クライアント名',
                        isHead: true
                    }, {
                        key: 'cap',
                        content: '回答数',
                        isHead: true
                    }, {
                        key: 'start_date',
                        content: 'アンケート開始日',
                        isHead: true
                    }, {
                        key: 'end_date',
                        content: 'アンケート終了日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: '終了したアンケートが存在しません',
                repletion: '終了したアンケートがここに表示されます。'
            }
        };

        /**
         * component - コンポーネント名算出処理
         * @return {String} - ステップに応じたコンポーネント名
         */
        const component = computed(() => alias[tabNavigation.currentIndex.value]);

        /**
         * activeSurveyActionMenu - 実施中アンケート操作メニュー
         * @type {Object}
         * @property {ref<Boolean>}  isExpanded - メニュー開閉フラグ
         * @property {ref<String>}   currentId  - 現在開いているメニューのID
         * @property {String}        placement  - メニュー表示位置
         * @property {Array<Object>} menus      - メニューの内容
         */
        const activeSurveyActionMenu = {
            isExpanded: ref(false),
            currentId: ref(''),
            placement: 'bottom-right',
            menus: [
                {label: 'Excel出力', emitEvent: 'exportExcelFile'},
                {label: '結果を見る', emitEvent: 'duplicateSurvey'}
            ]
        };

        /**
         * pendingSurveyActionMenu - 申請中アンケート操作メニュー
         * @type {Object}
         * @property {ref<Boolean>}  isExpanded - メニュー開閉フラグ
         * @property {ref<String>}   currentId  - 現在開いているメニューのID
         * @property {String}        placement  - メニュー表示位置
         * @property {Array<Object>} menus      - メニューの内容
         */
        const pendingSurveyActionMenu = {
            isExpanded: ref(false),
            currentId: ref(''),
            placement: 'bottom-right',
            menus: [
                {label: 'Excel出力', emitEvent: 'exportExcelFile'},
                {label: '結果を見る', emitEvent: 'duplicateSurvey'}
            ]
        };

        /**
         * draftSurveyActionMenu - 下書きアンケート操作メニュー
         * @type {Object}
         * @property {ref<Boolean>}  isExpanded - メニュー開閉フラグ
         * @property {ref<String>}   currentId  - 現在開いているメニューのID
         * @property {String}        placement  - メニュー表示位置
         * @property {Array<Object>} menus      - メニューの内容
         */
        const draftSurveyActionMenu = {
            isExpanded: ref(false),
            currentId: ref(''),
            placement: 'bottom-right',
            menus: [
                {label: 'Excel出力', emitEvent: 'exportExcelFile'},
                {label: '結果を見る', emitEvent: 'duplicateSurvey'}
            ]
        };

        /**
         * waitingSurveyActionMenu - 請求待ちアンケート操作メニュー
         * @type {Object}
         * @property {ref<Boolean>}  isExpanded - メニュー開閉フラグ
         * @property {ref<String>}   currentId  - 現在開いているメニューのID
         * @property {String}        placement  - メニュー表示位置
         * @property {Array<Object>} menus      - メニューの内容
         */
        const waitingSurveyActionMenu = {
            isExpanded: ref(false),
            currentId: ref(''),
            placement: 'bottom-right',
            menus: [
                {label: 'Excel出力', emitEvent: 'exportExcelFile'},
                {label: '請求済とする', emitEvent: 'openModal'},
                {label: '結果を見る', emitEvent: 'duplicateSurvey'}
            ]
        };

        /**
         * finishedSurveyActionMenu - 終了アンケート操作メニュー
         * @type {Object}
         * @property {ref<Boolean>}  isExpanded - メニュー開閉フラグ
         * @property {ref<String>}   currentId  - 現在開いているメニューのID
         * @property {String}        placement  - メニュー表示位置
         * @property {Array<Object>} menus      - メニューの内容
         */
        const finishedSurveyActionMenu = {
            isExpanded: ref(false),
            currentId: ref(''),
            placement: 'bottom-right',
            menus: [
                {label: 'Excel出力', emitEvent: 'exportExcelFile'},
                {label: '請求待ちに戻る', emitEvent: 'openModal'},
                {label: '結果を見る', emitEvent: 'duplicateSurvey'}
            ]
        };

        /**
         * deleteSurveyAlertModal - 削除アラートモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - 読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const deleteSurveyAlertModal = {
            id: 'delete-alert-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'alert',
            closable: false,
            title: 'アンケートを削除しますか？',
            description: '削除されたアンケートは、元に戻すことができません。',
            ariaDescribedby: 'delete-alert-modal-description'
        };

        /**
         * isRequestedToTrueAlertModal - 請求済にするアラートモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - 読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const isRequestedToTrueConfirmModal = {
            id: 'to-true-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'confirm',
            closable: false,
            title: 'アンケートを請求済にしますか？',
            description: '請求済にしたアンケートは、再度請求待ち状態に戻ることが可能です。',
            ariaDescribedby: 'to-true-confirm-modal-description'
        };

        /**
         * isRequestedToTrueAlertModal - 請求待ちにするアラートモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - 読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const isRequestedToFalseConfirmModal = {
            id: 'to-false-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'confirm',
            closable: false,
            title: 'アンケートを請求待ち状態に戻しますか？',
            description: '請求待ち状態に戻したアンケートは、再度請求済状態に変更することが可能です。',
            ariaDescribedby: 'to-false-confirm-modal-description'
        };

        /**
         * withdrawSurveyAlertModal - 申請取り下げアラートモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - 読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const withdrawSurveyAlertModal = {
            id: 'withdraw-alert-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'alert',
            closable: false,
            title: '申請を取り下げますか？',
            description: '取り下げた場合、アンケートは下書きとして保存されます。',
            ariaDescribedby: 'withdraw-alert-modal-description'
        };

        /**
         * requestSurveyConfirmModal - 申請取り下げアラートモーダル情報
         * @type {Object}
         * @property {String}        id              - モーダルのID名
         * @property {ref<String>}   currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>}  isExpanded      - モーダル起動フラグ
         * @property {String}        appearance      - モーダルの表示形式
         * @property {Boolean}       closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}        title           - 表示するタイトルの文字
         * @property {Array<String>} description     - 表示する説明文
         * @property {String}        ariaDescribedby - aria-describedby属性値
         */
        const requestSurveyConfirmModal = {
            id: 'request-confirm-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            appearance: 'confirm',
            closable: false,
            title: '申請いただけないアンケートです',
            description: [
                '設問が登録されていないか、開始希望日が昨日以前に設定されているアンケートは配信することができません。',
                '「設問の編集」より設問の設定を行なってください。'
            ],
            ariaDescribedby: 'request-confirm-modal-description'
        };

        /**
         * currentTableData - ページに応じたテーブルデータの算出
         */
        const currentTableData = computed(() => [activeSurveyTableData, pendingSurveyTableData, draftSurveyTableData, waitingSurveyTableData, finishedSurveyTableData][tabNavigation.currentIndex.value]);


        /**
         * isDownloading - tableauの集計ファイルダウンロード制御state
         * @type {ref<Boolean>}
         */
        const isDownloading = ref(false);

        /**
         * models - フォームモデル
         * @type {Object}
         * @property {reactive<Object>} search - ログインのモデル
         * @property {String} search.email     - 登録メールアドレス
         * @property {String} search.password  - パスワード
         */
        const models = {
            activeSearch: reactive({
                clientId: '',
                clientName: '',
                surveyId: ''
            }),
            pendingSearch: reactive({
                clientId: '',
                clientName: '',
                surveyId: ''
            }),
            draftSearch: reactive({
                clientId: '',
                clientName: '',
                surveyId: ''
            }),
            waitingSearch: reactive({
                clientId: '',
                clientName: '',
                surveyId: ''
            }),
            finishedSearch: reactive({
                clientId: '',
                clientName: '',
                surveyId: ''
            }),
            searchedKey: reactive({
                clientId: '',
                clientName: '',
                surveyId: ''
            }),
            currentSurveyName: ''
        };

        /**
         * forms - フォームのデータ
         * @type {Object}
         * @property {ref<Boolean>} isLoading - 読み込み中フラグ
         * @property {Object}       header    - フォームヘッダー部分のデータ
         * @property {Object}       email     - 登録メールアドレス入力欄のデータ
         * @property {Object}       password  - パスワード入力欄のデータ
         */
        const forms = {
            isLoading: ref(false),
            clientId: {
                id: 'client_id',
                name: 'client_id',
                label: 'クライアントID',
                placeholder: 'クライアントID',
                isRequire: false
            },
            clientName: {
                id: 'client_name',
                name: 'client_name',
                label: 'クライアント名',
                placeholder: 'クライアント名',
                isRequire: false
            },
            surveyId: {
                id: 'survey_id',
                name: 'survey_id',
                label: 'アンケートID',
                placeholder: 'アンケートID',
                isRequire: false
            }
        };

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * openBlank - 外部リンク別窓開き
             * @param {String} url - 開くURL
             */
            openBlank(url) {
                window.open(url);
            },
            /**
             * getSurveys - APIからアンケート一覧を取得
             * @param {Object} params - 送信するパラメータ
             * @returns {Object<{ bonuses: Array, meta: Object}>}
             */
            async getSurveys(params) {
                const result = {};

                await axios.get('/api/v2/admin_account/bonuses', {
                    headers: {...store.state.auth},
                    params: Object.assign(params, $route.query)
                }).then((response) => {
                    const {bonuses, meta} = response.data;

                    result.bonuses = bonuses.map((bonus) => ([
                        {key: 'bonus_id', content: bonus.id, resizing: 'hug'},
                        {key: 'bonus_name', content: bonus.bonus_name},
                        {key: 'client_id', content: bonus.client_id, resizing: 'hug'},
                        {key: 'client_name', content: bonus.client?.name},
                        {key: 'cap', content: `${bonus.validate_count}/${bonus.cap}`},
                        {key: 'start_date', content: new Date(bonus.start_date).toLocaleDateString() || ''},
                        {key: 'end_date', content: new Date(bonus.end_date).toLocaleDateString() || ''},
                        {
                            key: 'action',
                            content: bonus.id,
                            resizing: 'hug',
                            downloadEnabled: bonus.can_tableau_data
                        }
                    ]));

                    result.meta = meta;
                });

                return result;
            },
            /**
             * getSurveyTableData - アンケート一覧を取得・データ更新
             * @param {Object} targetTable - 更新するテーブルデータ
             * @param {Object} params      - 送信するパラメータ
             * @returns {Void}
             */
            getSurveyTableData(targetTable, params) {
                // すでに読み込み中の場合は取得しない
                if (targetTable.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                targetTable.isLoading.value = true;

                // データ取得
                methods.getSurveys(params).then((response) => {
                    const {bonuses, meta} = response;

                    // テーブルデータを更新
                    targetTable.bodyRows.value = bonuses;

                    // ページネーションを更新
                    targetTable.pagination.currentPage.value = meta.current_page;
                    targetTable.pagination.pages.value = Array.from(Array(meta.total_pages).keys(), (idx) => idx + 1);

                    // 現在のページが合計ページ数を逸脱している場合
                    if (meta.current_page > meta.total_pages) {
                        $router.replace({query: {...$route.query, ...{page: meta.total_pages}}});
                    }
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    targetTable.isLoading.value = false;
                });
            },

            tableauDataDownload(surveyId) {
                // すでに読み込み中の場合は取得しない
                if (isDownloading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                isDownloading.value = true;

                axios.get(`/api/v2/client_account/bonuses/${surveyId}/tableau_download`, {
                    headers: store.state.auth,
                    responseType: 'blob'
                }).then((response) => {
                    // 取得したファイルをダウンロードできるようにする
                    const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));

                    const link = document.createElement('a');
                    link.href = fileURL;

                    link.setAttribute('download', 'data.zip');
                    link.click();

                    // トーストを表示
                    $.emit('displayToast', {
                        message: 'ダウンロードを開始しました',
                        isError: false,
                        icon: 'Success'
                    });
                }).catch((error) => {
                    // トーストを表示
                    $.emit('displayToast', {
                        message: error[0],
                        isError: true,
                        icon: 'Failed'
                    });
                }).finally(() => {
                    isDownloading.value = false;
                });
            },

            // URLクエリ変更時利用される検索情報
            handleSearchTableData() {
                switch (tabNavigation.currentIndex.value) {
                case 0:
                    methods.searchTableData(models.activeSearch);
                    break;
                case 1:
                    methods.searchTableData(models.pendingSearch);
                    break;
                case 2:
                    methods.searchTableData(models.draftSearch);
                    break;
                case 3:
                    methods.searchTableData(models.waitingSearch);
                    break;
                case 4:
                    methods.searchTableData(models.finishedSearch);
                    break;
                default:
                    methods.searchTableData(models.activeSearch);
                }
            },

            // 検索処理
            searchTableData(model) {
                models.searchedKey.clientId = model.clientId.trim();
                models.searchedKey.clientName = model.clientName.trim();
                models.searchedKey.surveyId = model.surveyId.trim();

                // 請求待ちと終了タブ時の送信パラメーター設定
                const [clientId, surveyId, clientName] = [model.clientId, model.surveyId, model.clientName].map((value) => value.trim().toString());
                const currentIndex = tabNavigation.currentIndex.value;
                const status = currentIndex === 3 || currentIndex === 4 ? 'is_finished' : `is_${alias[currentIndex].toLowerCase()}`;
                const waitingAndFinishedParams = {
                    status,
                    includes: ['client'],
                    ...(clientId.length > 0 ? {'where[client_id]': clientId} : {}),
                    ...(surveyId.length > 0 ? {'where[id]': surveyId} : {}),
                    ...(clientName.length > 0 ? {'where[client_name]': clientName} : {})
                };

                if (status === 'is_finished') {
                    waitingAndFinishedParams['where[is_requested]'] = currentIndex === 4;
                }

                methods.getSurveyTableData(currentTableData.value, waitingAndFinishedParams);
            },
            /**
             * updateRequestStatus - 請求ステータス変更
             * @param {Number} surveyId - アンケートのID
             * @returns {Void}
             */
            updateRequestStatus() {
                const requestFlag = tabNavigation.currentIndex.value === 3;
                const modal = requestFlag ? isRequestedToTrueConfirmModal : isRequestedToFalseConfirmModal;
                const toastMessage = requestFlag ? '請求済に変更しました。' : '請求待ちに変更しました。';

                const currentId = modal.currentId.value;

                // すでに処理中の場合は何もしない
                if (modal.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                modal.isLoading.value = true;

                axios.patch(
                    `/api/v2/admin_account/bonuses/${currentId}/set_request_status`,
                    {is_requested: requestFlag},
                    {headers: store.state.auth}
                ).then(() => {
                    // トーストを表示
                    $.emit('displayToast', {
                        message: toastMessage,
                        isError: false,
                        icon: 'Success'
                    });

                    // モーダルを閉じる
                    modal.isExpanded.value = false;

                    // 再読み込みを実施
                    methods.getSurveyTableData(currentTableData.value, {
                        status: 'is_finished',
                        includes: ['client'],
                        'where[is_requested]': !requestFlag,
                        ...(models.searchedKey.clientId.toString().length > 0 ? {'where[client_id]': models.searchedKey.clientId} : {}),
                        ...(models.searchedKey.surveyId.toString().length > 0 ? {'where[id]': models.searchedKey.surveyId} : {}),
                        ...(models.searchedKey.clientName.toString().length > 0 ? {'where[client_name]': models.searchedKey.clientName} : {})
                    });
                }).catch(() => {
                    // エラートーストを表示
                    $.emit('displayToast', {
                        message: '請求状態変更に失敗しました',
                        isError: true,
                        icon: 'Failed'
                    });
                }).finally(() => {
                    // 読み込みフラグを更新（完了）
                    modal.isLoading.value = false;
                });
            },
            getSurveyName(tableRowProps) {
                models.currentSurveyName = tableRowProps.cells[1].content;
            },
            /**
             * exportExcelFile - Excel出力
             * @param {Number} surveyId - アンケートのID
             * @returns {Void}
             */
            exportExcelFile(surveyId) {
                axios.get(`/api/v2/admin_account/bonuses/${surveyId}/excel_download`, {
                    headers: store.state.auth,
                    responseType: 'blob',
                    timeout: 600000 // 10分のタイムアウトを設定（600,000ミリ秒）
                }).then((response) => {
                    const blob = new Blob([response.data], {type: response.headers['content-type']});

                    // ダウンロード用のURLを生成
                    const downloadUrl = window.URL.createObjectURL(blob);

                    // <a> 要素を作成し、ダウンロードをトリガー
                    const a = document.createElement('a');
                    a.href = downloadUrl;

                    const today = new Date();
                    const year = today.getFullYear();
                    const month = String(today.getMonth() + 1).padStart(2, '0');
                    const day = String(today.getDate()).padStart(2, '0');

                    const filename = `${surveyId}_${models.currentSurveyName}_${year}${month}${day}.xlsx`;

                    a.download = filename;

                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    // メモリリークを防ぐため、オブジェクトURLを解放
                    window.URL.revokeObjectURL(downloadUrl);
                }).catch((error) => {
                    console.error(error);
                    $.emit('displayToast', {
                        message: 'Excelダウンロードに失敗しました。',
                        isError: true,
                        icon: 'Failed'
                    });
                });
            }
        };

        /**
         * Models内のデータリセット
         * @type {Function}
         */
        function resetModels(modelsData) {
            Object.keys(modelsData).forEach((key) => {
                const subModel = modelsData[key];
                if (subModel && typeof subModel === 'object') {
                    Object.keys(subModel).forEach((subKey) => {
                        subModel[subKey] = '';
                    });
                }
            });
        }

        /**
         * URLパス変更監視処理
         * @type {Function}
         */
        watch(() => $route.path, (path) => {
            resetModels(models);

            const lastSegment = path.substring(path.lastIndexOf('/') + 1);
            const index = alias.map((alia) => alia.toLowerCase()).indexOf(lastSegment);

            // /surveys 以外のページヘ遷移する場合は何もしない
            if (index === -1) {
                return;
            }

            tabNavigation.currentIndex.value = index;
            if (index === 3 || index === 4) {
                methods.getSurveyTableData(currentTableData.value, {
                    status: 'is_finished',
                    includes: ['client'],
                    'where[is_requested]': index === 3 ? 'false' : 'true'
                });
            } else {
                methods.getSurveyTableData(currentTableData.value, {status: `is_${alias[index].toLowerCase()}`, includes: ['client']});
            }
        }, {immediate: true});

        /**
         * URLクエリ変更監視処理
         * @type {Function}
         */
        watch(() => $route.query, (query) => {
            const currentQuery = tabNavigation.tabs[tabNavigation.currentIndex.value].to.query || {};

            // クエリリセット（別ページへの遷移）の場合は処理しない
            if (!Object.values(query).length) {
                return;
            }

            tabNavigation.tabs[tabNavigation.currentIndex.value].to.query = query;

            // クエリに変更がない場合は読み込み処理を行わない
            if (JSON.stringify(Object.entries(currentQuery).sort()) === JSON.stringify(Object.entries(query).sort())) {
                return;
            }

            const currentTable = currentTableData.value;

            currentTable.pagination.currentPage.value = parseInt(query.page, 10);

            const [clientId, surveyId, clientName] = [models.searchedKey.clientId, models.searchedKey.surveyId, models.searchedKey.clientName].map((value) => value.trim().toString());
            const currentIndex = tabNavigation.currentIndex.value;
            const status = currentIndex === 3 || currentIndex === 4 ? 'is_finished' : `is_${alias[currentIndex].toLowerCase()}`;
            const waitingAndFinishedParams = {
                status,
                includes: ['client'],
                ...(clientId.length > 0 ? {'where[client_id]': clientId} : {}),
                ...(surveyId.length > 0 ? {'where[id]': surveyId} : {}),
                ...(clientName.length > 0 ? {'where[client_name]': clientName} : {})
            };

            if (status === 'is_finished') {
                waitingAndFinishedParams['where[is_requested]'] = currentIndex === 4;
            }

            methods.getSurveyTableData(currentTableData.value, waitingAndFinishedParams);
        }, {immediate: true});

        return {
            tabNavigation,
            activeSurveyTableData,
            pendingSurveyTableData,
            draftSurveyTableData,
            waitingSurveyTableData,
            finishedSurveyTableData,
            activeSurveyActionMenu,
            pendingSurveyActionMenu,
            draftSurveyActionMenu,
            waitingSurveyActionMenu,
            finishedSurveyActionMenu,
            currentTableData,
            component,
            deleteSurveyAlertModal,
            isRequestedToTrueConfirmModal,
            isRequestedToFalseConfirmModal,
            withdrawSurveyAlertModal,
            requestSurveyConfirmModal,
            models,
            forms,
            methods
        };
    }
});
</script>

<style lang="scss" scoped></style>
